<script setup lang="ts">
import { leadCollectionModalStateKey } from "~/types/injectKeys";
import type { ModalState } from "~/types/modal";
import type { BlogPost, Testimonial } from "~/types/data";

const { locale, localeProperties, t } = useI18n();
const { getLocalizedRoute } = useLocalizedRoute();
const gql = useGql();
const { data, error } = await useAsyncData(
  "homepage",
  async () => {
    const homeReq = gql("GetHomeInformations", {
      slug: getLocalizedRoute(locale.value, "index").backendSlug ?? "",
    });

    const [home] = await Promise.all([homeReq]);

    const pageHome = home.page?.generalPageOptions?.pageHome;

    return {
      content: {
        hero: {
          title: pageHome?.heroTitle,
          description: pageHome?.heroDescription,
          bulletPoints: parseBulletPoints(pageHome?.heroBulletPoints ?? ""),
          image: {
            src: pageHome?.heroImage?.node?.sourceUrl,
            alt: pageHome?.heroImageAltText,
          },
          ctaLabel: pageHome?.heroCtaButtonLabel,
        },
        visas: {
          title: t("visasAndGreenCards"),
          items: [
            ...(pageHome?.visas?.edges?.map(edge => ({
              title: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.title,
              subtitle: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.subtitle,
              excerpt: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.excerpt,
              image: {
                url: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.image?.node?.sourceUrl,
                alt: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.image?.node?.altText,
              },
              to: `${getLocalizedRoute(locale.value, "visas").path}/${edge.node?.slug?.replace(
                `visa-category-${locale.value}-`,
                "",
              )}`,
            })) ?? []),
            ...(pageHome?.greenCards?.edges?.map(edge => ({
              title: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.title,
              subtitle: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.subtitle,
              excerpt: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.excerpt,
              image: {
                url: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.image?.node?.sourceUrl,
                alt: edge.node?.services?.nodes?.[0]?.serviceoptions?.cardDetails?.image?.node?.altText,
              },
              to: `${getLocalizedRoute(locale.value, "greenCard").path}/${edge.node?.slug?.replace(
                `visa-category-${locale.value}-`,
                "",
              )}`,
            })) ?? []),
          ].filter(item => item.title), // filter empty titles
          seeAllLabel: pageHome?.visaButton,
        },
        applicationProcess: {
          title: pageHome?.applicationProcessTitle,
          list: Object.entries(pageHome?.applicationProcess || {})
            .map(([_, process]: [string, any]) => ({
              title: process?.title,
              subtitle: process?.subtitle,
              description: process?.description,
              image: {
                url: process?.image?.node?.sourceUrl ?? "/",
                alt: process?.title,
              },
            })),
        },
        testimonials: {
          title: pageHome?.testimonialsTitle,
          list: pageHome?.testimonials?.edges.map((edge): Testimonial => ({
          // @ts-expect-error We know that the edge.node is a Testimonial
            avatar: edge?.node?.testimonialOptions?.image?.node?.sourceUrl
              ? {
                // @ts-expect-error We know that the edge.node is a Testimonial
                  url: edge?.node?.testimonialOptions?.image?.node?.sourceUrl,
                  // @ts-expect-error We know that the edge.node is a Testimonial
                  alt: `${edge?.node?.testimonialOptions?.firstname} ${edge?.node?.testimonialOptions?.lastname}`,
                }
              : {
                  url: imagePlacholder.avatar,
                  // @ts-expect-error We know that the edge.node is a Testimonial
                  alt: `${edge?.node?.testimonialOptions?.firstname} ${edge?.node?.testimonialOptions?.lastname}`,
                },
            // @ts-expect-error We know that the edge.node is a Testimonial
            name: `${edge?.node?.testimonialOptions?.firstname} ${edge?.node?.testimonialOptions?.lastname}`,
            // @ts-expect-error We know that the edge.node is a Testimonial
            company: edge?.node?.testimonialOptions?.company ?? "",
            // @ts-expect-error We know that the edge.node is a Testimonial
            title: edge?.node?.testimonialOptions?.title ?? "",
            // @ts-expect-error We know that the edge.node is a Testimonial
            review: edge?.node?.testimonialOptions?.review ?? "",
            to: "#",
          })),
        },
        blog: {
          title: pageHome?.blogTitle,
          list: pageHome?.blogPosts?.nodes.map((node): BlogPost => ({
            // @ts-expect-error We know that the node is a Post
            image: node.featuredImage?.node?.sourceUrl
              ? {
                  // @ts-expect-error We know that the node is a Post
                  url: node.featuredImage?.node?.sourceUrl,
                  // @ts-expect-error We know that the node is a Post
                  alt: node.featuredImage?.node?.altText ?? "",
                }
              : {
                  url: "http://cms.grapelaw.com/wp-content/uploads/2024/04/blog_placeholder.jpg",
                  alt: "",
                },
            // @ts-expect-error We know that the node is a Post
            title: node.title || "",
            // @ts-expect-error We know that the node is a Post
            category: node.categories?.edges[0]?.node.name || "",
            // @ts-expect-error We know that the node is a Post
            date: node.date ? formatDate(node.date, localeProperties.value.iso) : new Date().toLocaleDateString(),
            // @ts-expect-error We know that the node is a Post
            to: `${getLocalizedRoute(locale.value, "blog").path}/${node.slug}`,
          })),
        },
      },
    };
  },
);

if (error.value) {
  throw createError({
    statusCode: error.value.statusCode,
    statusMessage: error.value.statusMessage,
    fatal: true,
  });
}

const { toggleModal } = inject<ModalState>(leadCollectionModalStateKey, {
  modalOpen: ref(false),
  toggleModal: () => { },
});
</script>

<template>
  <div class="space-y-16 pb-24">
    <!-- Hero -->
    <Hero
      :title="data?.content?.hero?.title"
      :description="data?.content?.hero?.description"
      :image="{
        src: data?.content?.hero?.image?.src,
        alt: data?.content?.hero?.image?.alt,
      }"
      :list="data?.content?.hero?.bulletPoints"
      :cta="{
        label: data?.content?.hero?.ctaLabel,
        click: toggleModal,
      }"
    />

    <UContainer class="space-y-24">
      <div class="space-y-12">
        <!-- Visas & Green Cards -->
        <div>
          <SectionTitle
            :title="data?.content?.visas?.title"
            :link="{
              text: data?.content?.visas?.seeAllLabel ?? $t('seeAll'),
              href: `${getLocalizedRoute(locale, 'visas').path}`,
            }"
          />
          <CardVisa
            :visas="data?.content?.visas?.items ?? []"
            overlay-hover
          />
        </div>
      </div>

      <!-- Application Process -->
      <div>
        <SectionTitle
          :title="data?.content?.applicationProcess?.title"
          center-title-in-mobile
        />

        <div class="mx-auto grid w-full grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xl:gap-0">
          <div
            v-for="(process, index) in data?.content?.applicationProcess?.list ?? []"
            :key="index"
            class="flex justify-center"
          >
            <div class="flex w-full flex-col items-center md:items-start">
              <div class="h-14">
                <NuxtPicture
                  :src="process.image.url"
                  alt=""
                />
              </div>
              <p class="pt-8 text-sm">
                {{ process.title }}
              </p>
              <p class="pt-2 text-xl font-bold text-text-title">
                {{ process.subtitle }}
              </p>
              <p class="text-gray-600 w-full pt-3 text-center text-sm leading-loose md:text-left">
                {{ process.description }}
              </p>
            </div>

            <div
              v-if="Number(index) !== 3"
              class="relative hidden h-full w-fit px-12 xl:block"
            >
              <div class="h-full w-[2px] bg-gradient-to-t from-transparent via-snow-200 to-transparent" />
              <div class="absolute inset-y-0 flex -translate-x-1/2 items-center">
                <UButton
                  color="white"
                  :aria-label="t('nextStepButtonLabel')"
                  class="rounded-full ring-text-title/10 hover:cursor-default hover:bg-white"
                  size="lg"
                >
                  <template #trailing>
                    <IconChevronRight class="size-5" />
                  </template>
                </UButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Testimonials -->
      <div>
        <SectionTitle
          :title="data?.content?.testimonials?.title"
          :link="{
            text: $t('seeAll'),
            href: `${getLocalizedRoute(locale, 'testimonial').path}`,
          }"
        />

        <UCarousel
          v-slot="{ item }"
          :items="data?.content?.testimonials?.list ?? []"
          :ui="{
            container: 'gap-6',
            item: 'basis-full md:basis-1/2 lg:basis-1/3 snap-start',
          }"
          :prev-button="{
            color: 'white',
            icon: 'i-heroicons-chevron-left-20-solid',
            class: '-ml-7 shadow-lg text-text-paragraph',
          }"
          :next-button="{
            color: 'white',
            icon: 'i-heroicons-chevron-right-20-solid',
            class: '-mr-8 shadow-lg text-text-paragraph',
          }"
          arrows
        >
          <LazyCardTestimonial :testimonials="[item]" />
        </UCarousel>
      </div>

      <!-- Blog -->
      <div>
        <SectionTitle
          :title="data?.content?.blog?.title"
          :link="{
            text: $t('seeAll'),
            href: `${getLocalizedRoute(locale, 'blog').path}`,
          }"
        />

        <!-- Negative margin trick to prevent the hover shadow of card from being cut off -->
        <div class="-ml-4">
          <!-- If item size is less than 2, indicators cause invalid array length error -->
          <UCarousel
            v-slot="{ item }"
            :items="data?.content?.blog?.list ?? []"
            :ui="{
              container: 'gap-2 pt-1 pb-10',
              item: 'md:basis-1/2 lg:basis-1/3 xl:basis-1/4 snap-start pl-4',
              indicators: {
                wrapper: 'static',
                active: 'bg-text-title',
                inactive: 'bg-snow-200/70 mix-blend-normal',
              },
            }"
            :indicators="data?.content?.blog?.list && data?.content?.blog?.list?.length > 1"
          >
            <LazyCardBlogItemCarousel
              :posts="[item]"
            />
          </UCarousel>
        </div>
      </div>
    </UContainer>

    <div class="space-y-24">
      <!-- Footer Banner -->
      <LazyFooterBanner
        :page-slug="getLocalizedRoute(locale, 'index').backendSlug"
        hide-cta
      />
      <!-- Contact Form -->
      <UContainer>
        <LazyFormContact
          mode="horizontal"
          narrow
        />
      </UContainer>
    </div>
  </div>
</template>
